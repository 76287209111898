import React from 'react';

export class TestComponent1 extends React.Component {
    constructor(props) {
        super();
        //this.testStr = props.dataStr;
        this.testStr = "!!!!!!!!!!!!!!!!!!!1";
        console.log(props);
    };

    render() {
        console.log(this.testStr);
        return (
            <div>
                <h1>TestComponent1</h1>
                <h1>{this.testStr}</h1>
            </div >
        )
    }
}

