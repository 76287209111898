import React from 'react';
import authService from './api-authorization/AuthorizeService';
import "./SentenceContructor.css";

const Brick = ({ brick, index, exNumber, dragBrick, doubleClick, ...props }) => {
    return (
        <div
            key={exNumber + "_b_" + index}
            className="brickWord"
            draggable="true"
            onDragStart={dragBrick}
            onDoubleClick={doubleClick}
        >
            {brick}
        </div>
    );
}

export default class SentenceContructor extends React.Component {
    constructor(props) {
        super();
        this.draggableBrickRef = React.createRef();
        this.draggableBricIsWord = undefined;
        this.bricksDivRef = React.createRef();
        this.wordsDivRef = React.createRef();
        this.parent = props.parentComponent;
        //this.firstEmpryWordDivRef = React.createRef();
        this.wordDivRefs = [];

        this.exercises = [];
        this.exerciseId = props.exerciseId;
        this.state = {
            loading: true,
            excerciseNmb: 0,
            exercise: null,
            isHelpViewMode: false
        };

        this.userTagToUnderline = false;
        //this.firstEmptyWordIsSetted = false;
    }

    getRefArrayWithMaxLenForTheExercise(exercises) {
        let maxWordCount = 0;
        for (let i = 0; i < exercises.length; i++) {
            maxWordCount = Math.max(maxWordCount, exercises[i].words.length);
        }

        let result = [];
        for (let i = 0; i < maxWordCount; i++) {
            result.push(React.createRef());
        }

        return result;
    }

    allowDrop = (event) => {
        event.preventDefault();
    };

    dragBrick = (event) => {
        this.draggableBrickRef.current = event.target;
        this.draggableBricIsWord = false;
    };

    dragWord = (event) => {
        this.draggableBrickRef.current = event.target;
        this.draggableBricIsWord = true;
    };

    drop = (event) => {
        if (this.draggableBrickRef.current === undefined) {
            this.draggableBricIsWord = undefined;
            return;
        }
        if (this.isEmptyBreakStr(event.target.innerText)) {
            event.target.innerText = this.draggableBrickRef.current.innerText;
            if (this.draggableBricIsWord) {
                let tagStr = this.getTag(this.draggableBrickRef.current.outerHTML);
                this.draggableBrickRef.current.innerText = tagStr;
            } else this.draggableBrickRef.current.className = "brickWord-hidden";
        }

        this.draggableBrickRef.current = undefined;
        this.draggableBricIsWord = undefined;
    };

    dropAnalogForDbClick = (target) => {
        if (this.draggableBrickRef.current === undefined) {
            this.draggableBricIsWord = undefined;
            return;
        }
        if (this.isEmptyBreakStr(target.innerText)) {
            target.innerText = this.draggableBrickRef.current.innerText;
            if (this.draggableBricIsWord) {
                let tagStr = this.getTag(this.draggableBrickRef.current.outerHTML);
                this.draggableBrickRef.current.innerText = tagStr;
            } else this.draggableBrickRef.current.className = "brickWord-hidden";
        }

        this.draggableBrickRef.current = undefined;
        this.draggableBricIsWord = undefined;
    };

    wordDrop = (event) => {
        if (this.draggableBrickRef.current === undefined) {
            this.draggableBrickRef.current = undefined;
            this.draggableBricIsWord = undefined;
            return;
        }

        if (this.isEmptyBreakStr(this.draggableBrickRef.current.innerText)) {
            this.draggableBrickRef.current = undefined;
            this.draggableBricIsWord = undefined;
            return;
        }

        if (this.draggableBricIsWord === true) {
            let textId = this.draggableBrickRef.current.innerText;

            let tagStr = this.getTag(this.draggableBrickRef.current.outerHTML);

            this.draggableBrickRef.current.innerText = tagStr;

            let elemToShow = undefined;
            for (let i = 0; i < this.bricksDivRef.current.children.length; i++) {
                if (this.bricksDivRef.current.children[i].innerText !== textId)
                    continue;
                elemToShow = this.bricksDivRef.current.children[i];
            }

            //elemToShow.style.display = "inline-block";
            elemToShow.className = "brickWord";
        }

        this.draggableBrickRef.current = undefined;
        this.draggableBricIsWord = undefined;
    };

    getTag = (str) => {
        if (!this.userTagToUnderline) return "________";
        //console.log(str);
        let n = str.indexOf('tag="');
        //console.log(n);
        if (n === -1) return "___";
        let f = str.indexOf('"', n + 5);
        //console.log(f);
        if (f === -1) return "___";

        return str.slice(n + 5, f);
    };

    isEmptyBreakStr = (str) => {
        //console.log(str);
        let len = str.length;
        let emptyLen = 0;
        for (let i = 0; i < len; i++) {
            if (str[i] === "_") emptyLen++;
        }
        if (len === emptyLen) {
            //console.log("empty")
            return true;
        } else {
            //console.log("NOT EMPTY");
            return false;
        }
    };

    btnCheckClick = (event) => {
        let array = this.wordsDivRef.current.children;
        let result = "";
        result = array[0].innerText;
        for (let i = 1; i < array.length; i++) {
            result = result + " " + array[i].innerText;
        }

        if (result === this.state.exercise.espSentence) {
            this.nextOnClick();
        } else {
            alert(" - неверный ответ!");
        }
    };

    btnNextClick = (event) => {

        // if (this.parent != null)
        //     this.parent.setNextExercise();
    }

    SetVisibleAll = () => {
        for (let i = 0; i < this.bricksDivRef.current.children.length; i++) {
            let elemToShow = this.bricksDivRef.current.children[i];
            elemToShow.style.display = "inline-block";
        }
    };

    componentDidMount() {
        this.populateData();
    }

    componentDidUpdate = () => {
        //this.SetVisibleAll();
    };

    nextOnClick = () => {
        if (this.exercises.length === this.state.excerciseNmb + 1) {
            //alert("Конец");
            if (this.parent != null) {
                this.parent.setExerciseComplete(this.exerciseId);
                this.parent.setNextExercise();
            }
            return;
        } else {
            let newNmb = this.state.excerciseNmb + 1;
            //this.exercise = this.exercises[newNmb];
            this.setState({ excerciseNmb: newNmb });
            this.setState({ exercise: this.exercises[newNmb] });

            //console.log(newNmb);
        }
    };

    //двой клик по нижнему прямоугольнику
    sentenceSetBrickDbClick = (event) => {
        //alert("sentenceBrickDbClick");
        this.dragWord(event);
        this.wordDrop(event);
    }

    //двойной клик по верхниму прямоугольнику
    brickDbClick = (event) => {

        //TODO: найти первый пустой нижний блок и присвоить его к firstEmptyWordDiv        
        let firstEmptyWordDiv = null;
        for (let i = 0; i < this.state.exercise.words.length; i++) {

            if (this.isEmptyBreakStr(this.wordDivRefs[i].current.innerText) === true) {
                firstEmptyWordDiv = this.wordDivRefs[i].current;
                break;
            }
        }
        if (firstEmptyWordDiv == null) return;


        this.dragBrick(event);
        this.dropAnalogForDbClick(firstEmptyWordDiv);
        //111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111111
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading exercise (sentence contructor)...</em></p>
            : this.renderSentence();
        return (
            <div>
                {contents}
            </div>
        );
    }

    renderSentence() {
        return (
            <div>
                <div className="ruSentence">{this.state.exercise.ruSentence}</div>
                <div
                    className="brickSet"
                    onClick={this.showcount}
                    ref={this.bricksDivRef}
                    onDragOver={this.allowDrop}
                    onDrop={this.wordDrop}
                >
                    {this.state.exercise.bricks.map((brick, index) => (

                        <Brick key={"br_" + this.state.excerciseNmb + "_" + index} brick={brick} exNumber={this.state.excerciseNmb} index={index} dragBrick={this.dragBrick}
                            doubleClick={this.brickDbClick} />

                    ))}
                </div>
                {this.state.isHelpViewMode === true ?
                    this.renderHelp() : <></>}


                <div className={"sentenceSet" + (this.state.isHelpViewMode === true ? " div-hidden" : "")} ref={this.wordsDivRef}>
                    {this.state.exercise.words.map((word, index) =>
                        this.isEmptyBreakStr(word) ? (
                            <div
                                key={this.state.excerciseNmb + "_w_" + index}
                                className="sentenceBrick"
                                draggable="true"
                                onDragOver={this.allowDrop}
                                onDrop={this.drop}
                                onDragStart={this.dragWord}
                                onDoubleClick={this.sentenceSetBrickDbClick}
                                tag={word}
                                ref={this.wordDivRefs[index]}
                            >
                                {this.userTagToUnderline ? word : "________"}
                            </div>
                        ) : (
                            <div key={"w" + index} className="fixSentenceBrick" ref={this.wordDivRefs[index]}>
                                {word}
                            </div>
                        )
                    )}
                </div>

                <div className='buttons'>
                    <button type="button" className="btn btn-success checkButton" onClick={this.btnCheckClick}>Проверить</button>
                </div>
                <div className='helpButtonDiv'  >
                    <input key="helpBtnNormalMode" type="image" src="images\question.png" alt="help" onMouseDown={this.showHelp} onMouseUp={this.hideHelp} onMouseLeave={this.hideHelp} />
                </div>
            </div>
        );
    }

    showHelp = (event) => {
        if (this.state.isHelpViewMode === true) return;
        this.setState({ isHelpViewMode: true });
        console.log("showHelp");
    }

    hideHelp = (event) => {
        if (this.state.isHelpViewMode === false) return;
        this.setState({ isHelpViewMode: false });
        console.log("hideHelp");
    }

    renderHelp() {
        let wordsLst = this.state.exercise.espSentence.split(' ');
        //console.log(wordsLst);
        return (
            <div key={"help_div_" + this.state.excerciseNmb} className="sentenceSet">
                {wordsLst.map((word, index) => (

                    <div key={"help_w" + index} className="fixSentenceBrick">
                        {word}
                    </div>
                ))}
            </div>
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/exercise/' + this.exerciseId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.exercises = data;
        this.wordDivRefs = this.getRefArrayWithMaxLenForTheExercise(this.exercises);
        this.setState({ exercise: this.exercises[0] });
        this.setState({ excerciseNmb: 0 });
        this.setState({ loading: false });
    }
}