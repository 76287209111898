import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './CourceInfo.css';

export class CourceInfo extends Component {

    static displayName = "AAAAAAAA";

    constructor(props) {
        super(props);
        this.state = {
            cource: null,
            units: [],
            unit: null,
            lessons: [],
            lessonNmb: 0,
            loading: true
        };
    }

    componentDidMount() {
        this.populateCource();
    }

    onUnitClick = (e, nmb) => {
        if (this.state.unit.unitNumber === nmb) return;
        this.setState({ unit: this.state.units[nmb] });
        this.setState({ lessons: this.state.units[nmb].extendLessons });
        this.setState({ lessonNmb: 0 });
    }

    onLessonClick = (e, nmb) => {
        this.setState({ lessonNmb: nmb });
    }

    onStartBtnClick = (e, lesson) => {
        // console.log(lesson);
        const url = `${window.location.protocol}//${window.location.host}/lesson?id=${lesson.id}&courceId=${this.state.cource.id}`;
        // console.log(url);
        window.location.assign(url);
        //window.location.assign(`${window.location.protocol}//` + `${window.location.host}/lesson`);
        //window.location.assign("https://localhost:5001/ImageByVoice");

    }

    renderCourceInfo() {
        return (
            <div>
                {/* <h1 id="tabelLabel" >Курс: {this.state.cource.courceName}</h1> */}
                <h1 id="tabelLabel" >EJERCICIOS</h1>
                {/*<div className="ExercisesInfoDiv1">Курс: {this.state.cource.courceName}</div>*/}
                <div key="allUnits" className="units">
                    <div className="unitsHeader">UNIDADES</div>
                    {this.state.units.map((unit, index) =>
                        <div key={unit.unitName}
                            className={unit.unitNumber === this.state.unit.unitNumber ? 'selectedUnit' : 'unselectedUnit'}
                            onClick={(e) => this.onUnitClick(e, index)}>
                            <span className="unitNumberDiv">{(unit.unitNumber + 1)}</span>
                        </div>
                    )}
                </div>
                {/*<hr />

                  <div key="unitLessons" className="lessons">
                    {this.state.lessons.map((lesson, index) =>
                        <div key={lesson.lessonName}
                            className={lesson.lessonNumber === this.state.lessonNmb ? 'selectedLesson' : 'unselectedLesson'}
                            onClick={(e) => this.onLessonClick(e, index)}>
                            Lección {lesson.lessonNumber + 1}
                        </div>
                    )}
                </div>
                <hr />
                <div>
                    <div className="lessonInfo">
                        <div className="ExercisesInfoDiv1">La lección de {this.state.lessons[this.state.lessonNmb].extendExercises.length} tareas</div>
                        <div className="ExercisesInfoDiv2">La lección completada en&nbsp;
                            {Math.round((100 * this.state.lessons[this.state.lessonNmb].extendExercises.filter(x => x.isCompleted === true).length) / this.state.lessons[this.state.lessonNmb].extendExercises.length)}
                            %</div>
                        <div className="btnStartDiv">
                            <button type="button" className="tn btn-primary"
                                onClick={(e) =>
                                    this.onStartBtnClick(e, this.state.lessons[this.state.lessonNmb])}>Comenzar la lección</button>
                        </div>
                    </div>
                                </div>*/}
                <hr />
                <div className='lessonsConainer'>
                    {this.state.lessons.map((lesson, index) =>
                        <div key={lesson.lessonName}
                            className='lessonRow'
                        //onClick={(e) => this.onLessonClick(e, index)}
                        >
                            <div className="ExercisesInfoDiv1">Lección {index + 1}</div>
                            <div className="ExercisesInfoDiv1">de {lesson.extendExercises.length} tareas</div>
                            <div className="ExercisesInfoDiv2">completada en&nbsp;
                                {Math.round((100 * lesson.extendExercises.filter(x => x.isCompleted === true).length) / lesson.extendExercises.length)}
                                %</div>
                            <div className="btnStartDiv">
                                <button type="button" className="tn btn-primary"
                                    onClick={(e) =>
                                        this.onStartBtnClick(e, lesson)}
                                >Comenzar la lección</button>
                            </div>
                        </div>
                    )}
                </div>
            </div >
        )
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.renderCourceInfo();

        return (
            <div>
                {/*<h1 id="tabelLabel" >Weather forecast</h1>
                <p>This component demonstrates fetching data from the server.</p>*/}
                {contents}
            </div>
        );
    }

    async populateCource() {
        const token = await authService.getAccessToken();
        const response = await fetch('/api/cource', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        this.setState({
            cource: data,
            units: data.extendUnits,
            unit: data.extendUnits[0],
            lessons: data.extendUnits[0].extendLessons,
            lessonNmb: 0
        });

        this.setState({
            loading: false
        });
    }
}
