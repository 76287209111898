import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './VocabularyGroup.css';
import currentCourceService from './CurrentCourceService';

import { VocabularyWrite } from './VocabularyWrite';
import { VocabularyGuess } from './VocabularyGuess';

export class VocabularyGroup extends Component {

    constructor(props) {

        console.log("!!!!!!!!!!!!!!!");
        super(props);
        const queryParams = new URLSearchParams(window.location.search);
        this.groupId = queryParams.get('id');
        //this.groupId = queryParams.get('groupId');
        //sthis.groupId = 2;
        // console.log("GROUP_ID = " + this.groupId);        
        this.modes = ['guess', 'write'];
        this.state = {
            mode: 0,
            group: null,
            cards: [],
            headerString: "",
            showExamples: false,
            loading: true
        };
    }

    componentDidMount() {
        this.populateLesson();
    }

    loadAllImages(cards) {
        console.log("image lading")
        cards.forEach((card) => {
            const img = new Image();
            img.src = card.image;
            console.log(card.image);
        });
    }

    onUnitClick = (e, nmb) => {

    }

    setHaederString(headerStr) {
        this.setState({ headerString: headerStr });
    }

    onShowExampleValueChange = (event) => {
        console.log(event.currentTarget.checked)
        this.setState({ showExamples: event.currentTarget.checked });
        //console.log("aaa" + this.state.showExamples);
    }

    onModeChange = (event) => {
        //console.log(event.currentTarget.selectedIndex);        
        this.setState({ mode: event.currentTarget.selectedIndex });
        // switch (event.currentTarget.value) {
        //     case 'Самопроверка':
        //         this.setState({ mode: 0 });
        //         break;
        //     case 'Написание слов':
        //         this.setState({ mode: 1 });
        //         break;
        // }
    }

    async setNextLevel() {
        //вызвать метод простановки новой даты повторения
        //вернуться к окну словаря

        const token = await authService.getAccessToken();
        const url = `${window.location.protocol}//${window.location.host}/api/vocabularyGroup/${this.groupId}`;
        console.log("#####:" + url);
        const response = await fetch(url, {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        //const data = await response.json();

        const urlVocabularies = `${window.location.protocol}//${window.location.host}/vocabularies`;
        window.location.assign(urlVocabularies);
    }


    renderExercise() {
        let vocabularyComponent;
        switch (this.modes[this.state.mode]) {
            case 'write':
                vocabularyComponent = <VocabularyWrite cards={this.state.cards} parentComponent={this} />;
                break;
            case 'guess':
                vocabularyComponent = <VocabularyGuess cards={this.state.cards} parentComponent={this} />
                break;
            default:
                vocabularyComponent = <div>ERROR</div>
                break;
        }
        return (
            <div id="all">
                <header id="groupHeader">
                    {this.state.headerString}
                    <select key='cbxVocabularyMode' className='form-select form-select-sm cbxVocabularyMode' onChange={this.onModeChange}>
                        < option >Самопроверка</option>
                        < option >Написание</option>
                    </select >
                </header>
                <div id="main">
                    {vocabularyComponent}
                </div>
                <footer id="vocabularyFooter">
                    <div className="custom-control custom-checkbox checkShowExample">
                        <input type="checkbox" className="custom-control-input" id="defaultUnchecked" onChange={this.onShowExampleValueChange} />
                        <label class="custom-control-label" for="defaultUnchecked">Отображать пример</label>
                    </div>

                </footer>
            </div >
        )
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading lesson...</em></p>
            : this.renderExercise();
        return (
            <div>
                {/*<h1 id="tabelLabel" >Weather forecast</h1>
                <p>This component demonstrates fetching data from the server.</p>*/}
                {contents}
            </div>
        );
    }

    async populateLesson() {
        const token = await authService.getAccessToken();
        const url = `${window.location.protocol}//${window.location.host}/api/vocabularyGroup/${this.groupId}`;
        const response = await fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        this.setState({
            group: data,
            cards: data.wordCards,
            headerString: "0/" + data.wordCards.length
        });

        this.loadAllImages(data.wordCards);

        this.setState({
            loading: false
        });

        // console.log("JastTestValue=" + currentCourceService._testValue);
        // console.log("testValue=" + currentCourceService.GetTestValeu());
        // console.log("testValue=" + currentCourceService.GetTestValeu());
        // console.log("currentCourceId=" + currentCourceService._currentCourceId);
    }
}
