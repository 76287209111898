import React from 'react';
import authService from './api-authorization/AuthorizeService';
import "./StatementByVoice.css";

const StatementTagsFromString = ({ statement, stmtNmb, taskNmb, regisrageMyRef, ...props }) => {
    var str = statement.statement;
    var strParts = str.split('*').filter((val) => val !== '');
    return (
        <div id={taskNmb + '_statement' + stmtNmb} key={taskNmb + '_statement' + stmtNmb} className='oneStatementDiv'>
            {strParts.map((part, index) =>
                <><span id={taskNmb + '_statement' + stmtNmb + '_part' + index} className='statementText'>{part}</span>
                    <StatementCombobox opts={statement.options} optionIndex={index}
                        curKey={taskNmb + '_statement' + stmtNmb + '_option' + index}
                        regisrageMyRef={regisrageMyRef} />
                </>
            )
            }
            <br />
        </div>
    )
}

const StatementCombobox = ({ opts, optionIndex, curKey, regisrageMyRef, ...props }) => {
    const ref = React.createRef();
    let contents = (opts.length <= optionIndex) ? <></>
        :
        (<select id={curKey} key={curKey} className='combobox' ref={ref}>
            < option ></option>
            {opts[optionIndex].map((val, idx) => < option > {val}</option>)}
        </select >);
    regisrageMyRef(curKey, ref);
    return (
        <>
            {contents}
        </>
    )
}

export default class CbxStatement extends React.Component {
    constructor(props) {
        super();
        this.parent = props.parentComponent;
        this.exerciseId = props.exerciseId;
        this.exercises = null;
        this.state = {
            loading: true,
            excerciseNmb: 0,
            exercise: null
        };
        this.resultsMap = null;
        this.cbxMap = null;
    }

    regisrageMyRef = (curKey, ref) => {
        console.log("REGISRATE!!!!!" + curKey)
        console.log(ref);
        if (this.cbxMap === null)
            this.cbxMap = new Map();
        this.cbxMap.set(curKey, ref);
    }


    btnCheckClick = (event) => {

        this.getRightAnswersMap(this.state.exercise);
        console.log("CHECK_START!!!!!!!!!!!!!!!!!!!!!");
        console.log(this.resultsMap);
        console.log(this.cbxMap);

        if (this.resultsMap === null || this.cbxMap === null)
            alert("ERROR: Map is empty!");
        let badAnswersCbxArray = [];
        this.resultsMap.forEach((value, key, map) => {
            //value===this.cbxMap.get(key).
            console.log("KEY: " + key + ";VALUE:" + value);
            console.log(this.cbxMap.get(key));
            let ref = this.cbxMap.get(key);
            let n = ref.current.options.selectedIndex;
            let text = ref.current.options[n].text;
            console.log(text);
            if (text !== value)
                badAnswersCbxArray.push(ref);
        });

        if (badAnswersCbxArray.length === 0) {
            this.cbxMap = new Map();
            this.resultsMap = new Map();
            this.btnNextClick();
        }
        else {
            let msg = "";
            badAnswersCbxArray.map((ref, index) => msg = msg + index + ". " + ref.current.options[ref.current.options.selectedIndex].text + "; ");
            alert(msg);
        }
    }

    getRightAnswersMap = (exercise) => {
        this.resultsMap = new Map();
        const taskNmb = this.state.excerciseNmb;
        // exercise.statements.map((statement, stmtNmb) => {
        //     statement.answers.map((answer, optionIndex) => {
        //         this.resultsMap.set(taskNmb + '_statement' + stmtNmb + '_option' + optionIndex, answer);
        //     })
        // })

        exercise.statements.forEach((statement, stmtNmb) => {
            statement.answers.forEach((answer, optionIndex) => {
                this.resultsMap.set(taskNmb + '_statement' + stmtNmb + '_option' + optionIndex, answer);
            })
        })
    }

    btnNextClick = (event) => {
        if (this.exercises.length === this.state.excerciseNmb + 1) {
            //alert("Конец");
            if (this.parent != null) {
                this.parent.setExerciseComplete(this.exerciseId);
                this.parent.setNextExercise();
            }
            return;
        } else {
            let newNmb = this.state.excerciseNmb + 1;
            //this.exercise = this.exercises[newNmb];
            this.setState({ excerciseNmb: newNmb });
            this.setState({ exercise: this.exercises[newNmb] });

            //console.log(newNmb);
        }
    }

    componentDidMount() {
        this.populateData();

    }

    componentDidUpdate() {

    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading exercise (StatementByVoice)...</em></p>
            : this.renderSentence();
        return (
            <div>
                {contents}
            </div>
        );
    }

    onSelectChange = (e, curKey) => {
        console.log("EVENT " + curKey);
    }

    renderSentence() {
        return (
            <div>
                <header className='divMainText'>
                    <span id="mainText">{this.state.exercise.allText}</span>

                </header>
                {this.state.exercise.statements.map((stmnt, index) =>
                    <StatementTagsFromString statement={stmnt} stmtNmb={index} taskNmb={this.state.excerciseNmb} regisrageMyRef={this.regisrageMyRef} />)}
                <div className='buttons'>
                    <button type="button" className="btn btn-success checkButton" onClick={this.btnCheckClick}>Проверить</button>
                </div>
                {/* <div className='helpButtonDiv'  >
                    <input key="helpBtnNormalMode" type="image" src="images\question.png" alt="help" onMouseDown={this.showHelp} onMouseUp={this.hideHelp} onMouseLeave={this.hideHelp} />
                </div> */}
            </div >
        );
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/exercise/' + this.exerciseId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.exercises = data;
        this.setState({ exercise: this.exercises[0] });
        this.setState({ excerciseNmb: 0 });
        this.setState({ loading: false });
    }
}