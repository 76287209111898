import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './ImageByVoice.css';


export default class ImageByVoice extends Component {
    constructor(props) {
        //console.log(window.location.href)
        super();
        this.exerciseId = props.exerciseId;
        this.state = { loading: true, excerciseNmb: 0 };
        this.exercises = [];
        this.exercise = null;
        this.audioRef = React.createRef();
        this.userAnswer = -1;
        this.parent = props.parentComponent;
    }

    static displayName = "BBBBBBBB";

    nextOnClick = () => {
        if (this.userAnswer === this.exercise.correctNumber) {
            if (this.exercises.length === this.state.excerciseNmb + 1) {
                alert("Конец");
                if (this.parent != null) {
                    this.parent.setExerciseComplete(this.exerciseId);
                    this.parent.setNextExercise();
                }
                return;
            }
            else {
                let newNmb = this.state.excerciseNmb + 1;
                this.exercise = this.shufleExercise(this.exercises[newNmb]);
                this.setState({ excerciseNmb: newNmb });
            }
        }
        else {
            alert(this.exercise.images[this.userAnswer].ruText + " - неверный ответ!");
        }
    }

    onImageClick = (e, nmb) => {
        this.userAnswer = nmb;
        this.nextOnClick();
    }

    shuffle(arr) {
        var j, temp;
        for (var i = arr.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            temp = arr[j];
            arr[j] = arr[i];
            arr[i] = temp;
        }
        return arr;
    }

    shufleExercise(exercise) {
        let correctImg = exercise.images[exercise.correctNumber];
        exercise.images = this.shuffle(exercise.images);
        exercise.correctNumber = exercise.images.indexOf(correctImg);
        return exercise;
    }

    // btnNextClick = (event) => {
    //     if (this.parent != null)
    //         this.parent.setNextExercise();
    // }

    onPlay(audioRef) {
        if (this.exercise == null)
            return;
        audioRef.current.play();
    }

    componentDidMount() {
        this.populateData();
        this.onPlay(this.audioRef);
    }

    componentDidUpdate() {
        this.onPlay(this.audioRef);
    }

    renderImages() {
        return (
            <div className="all">
                {/* <header id="h" className='h'> */}
                <header id="headImageByVoice" className='head'>
                    <audio ref={this.audioRef} controls src={this.exercise.audio}>
                        Your browser does not support the
                        <code>audio</code> element.
                    </audio>
                    <span id="freignWord">{this.exercise.espText}</span>
                </header>
                <div id="img0"><img src={this.exercise.images[0].img} alt={this.exercise.images[0].ruText} onClick={(e) => this.onImageClick(e, 0)} className="image6Lst" /><div>{this.exercise.images[0].ruText}</div></div>
                <div id="img1"><img src={this.exercise.images[1].img} alt={this.exercise.images[1].ruText} onClick={(e) => this.onImageClick(e, 1)} className="image6Lst" /><div>{this.exercise.images[1].ruText}</div></div>
                <div id="img2"><img src={this.exercise.images[2].img} alt={this.exercise.images[2].ruText} onClick={(e) => this.onImageClick(e, 2)} className="image6Lst" /><div>{this.exercise.images[2].ruText}</div></div>
                <div id="img3"><img src={this.exercise.images[3].img} alt={this.exercise.images[3].ruText} onClick={(e) => this.onImageClick(e, 3)} className="image6Lst" /><div>{this.exercise.images[3].ruText}</div></div>
                <div id="img4"><img src={this.exercise.images[4].img} alt={this.exercise.images[4].ruText} onClick={(e) => this.onImageClick(e, 4)} className="image6Lst" /><div>{this.exercise.images[4].ruText}</div></div>
                <div id="img5"><img src={this.exercise.images[5].img} alt={this.exercise.images[5].ruText} onClick={(e) => this.onImageClick(e, 5)} className="image6Lst" /><div>{this.exercise.images[5].ruText}</div></div>
            </div>
        )
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading exercise...</em></p>
            : this.renderImages();

        return (
            <div>
                {contents}
            </div>
        );
    }

    loadAllImages(exercises) {
        console.log("image lading")
        exercises.forEach((exercise) => {
            const img0 = new Image();
            img0.src = exercise.images[0];
            console.log(exercise.images[0]);

            const img1 = new Image();
            img1.src = exercise.images[1];
            console.log(exercise.images[1]);

            const img2 = new Image();
            img2.src = exercise.images[2];
            console.log(exercise.images[2]);


            const img3 = new Image();
            img3.src = exercise.images[3];
            console.log(exercise.images[3]);


            const img4 = new Image();
            img4.src = exercise.images[4];
            console.log(exercise.images[4]);

            const img5 = new Image();
            img5.src = exercise.images[5];
            console.log(exercise.images[5]);
        });
    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/exercise/' + this.exerciseId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.exercises = this.shuffle(data);
        this.exercise = this.shufleExercise(this.exercises[0]);
        this.state = { excerciseNmb: 0 };
        this.setState({
            loading: false
        });
    }
}


