import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './Vocabulary.css';

export class Vocabulary extends Component {

    constructor(props) {
        super(props);
        this.state = {
            units: [],
            unit: null,
            groups: [],
            loading: true
        };
    }

    componentDidMount() {
        this.populateVocabulary();
    }

    onUnitClick = (e, nmb) => {
        if (this.state.unit.unitNumber === nmb) return;
        this.setState({ unit: this.state.units[nmb] });
        this.setState({ groups: this.state.units[nmb].vocabularyGroups })
        // this.setState({ lessons: this.state.units[nmb].lessons });
        // this.setState({ lessonNmb: 0 });
    }

    onGroupClic = (e, id) => {
        ///api/vocabularyGroup/
        const url = `${window.location.protocol}//${window.location.host}/vocabularyGroup?id=${id}`;
        // console.log(url);
        window.location.assign(url);
    }

    renderCourceInfo() {
        return (
            <div>
                <h1>VOCABULARIO</h1>

                <div key="allUnits" className="lessons">
                    <div className="unitsHeader">UNIDADES</div>
                    {this.state.units.map((unit, index) =>
                        <div key={unit.unitName}
                            className={unit.unitNumber === this.state.unit.unitNumber ? 'selectedLesson' : 'unselectedLesson'}
                            onClick={(e) => this.onUnitClick(e, index)}>
                            <span className="unitNumberDiv">{(unit.name)}</span>
                        </div>
                    )}
                </div >
                <hr />

                <div>
                    {this.state.groups.map((group, index) =>
                        <div key={'groupId' + group.id}
                            className='vocabularyGroupDiv'>
                            <span className="vocabularyGroupNameSpan">{group.name}</span>
                            <span className="vocabularyGroupMessage">{group.userNotify} ({group.userStudyLevel}из7)</span>
                            <button type="button" className="tn btn-primary groupButton"
                                onClick={(e) => this.onGroupClic(e, group.id)}
                            >{group.buttonText}</button>
                        </div>)}
                </div>
            </div>
        )
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading vocabulary...</em></p>
            : this.renderCourceInfo();

        return (
            <div>
                {/*<h1 id="tabelLabel" >Weather forecast</h1>
                <p>This component demonstrates fetching data from the server.</p>*/}
                {contents}
            </div>
        );
    }

    async populateVocabulary() {
        const token = await authService.getAccessToken();
        console.log("populateVocabulary");
        //const url = `${window.location.protocol}//${window.location.host}/api/vocabularyinfo/${1}`;
        const url = `${window.location.protocol}//${window.location.host}/api/vocabularyinfo`;
        console.log(url);
        const response = await fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });

        console.log("DONE!");
        const data = await response.json();
        //const data = await response.text();
        console.log(data);
        //return;
        this.setState({
            cource: data,
            units: data.vocabularyUnits,
            unit: data.vocabularyUnits[0],
            groups: data.vocabularyUnits[0].vocabularyGroups
        });
        this.setState({
            loading: false
        });

    }

}