import React, { Component } from 'react';
//import authService from './api-authorization/AuthorizeService'
import './VocabularyGroup.css';
import InputMask from "react-input-mask";

export class VocabularyWrite extends Component {

    static displayName = "VocabularyWrite";

    constructor(props) {
        super(props);
        this.cards = props.cards;
        this.parent = props.parentComponent;
        this.audioWord = new Audio(); // Создаём новый элемент Audio
        this.audioExample = new Audio();
        this.toLearn = this.shuffle([...props.cards]);
        this.userInputRef = React.createRef();
        this.toRepeat = [];
        this.failAttemptCount = 0;
        this.state = {
            card: this.toLearn[0],
            buttons: 'one',
            shorErrorInfo: false,
            showCorrectWordInfo: false
        };
        this.n = 0;

    }

    getMaskForWord(word) {

        const n = word.length;
        let result = "";

        for (let i = 0; i < n; i++) {
            if (word[i] === ' ' || word[i] === '-' || word[i] === ',' || word[i] === '.' || word[i] === '!' || word[i] === '?' || word[i] === '¿' || word[i] === '¡' || word[i] === ':')
                result = result + word[i]
            else
                result = result + '*';
        }
        return result;
    }

    shuffle(arr) {
        var j, temp;
        for (var i = arr.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            temp = arr[j];
            arr[j] = arr[i];
            arr[i] = temp;
        }
        return arr;
    }
    btnCheckClick = (event) => {
        this.failAttemptCount = 3;
        this.setState({ buttons: 'two' });
    }

    btnCorrectClick = (event) => {
        this.failAttemptCount = 0;
        this.userInputRef.current.value = '';
        this.toLearn.shift();
        if (this.toLearn.length === 0) {
            this.toLearn = this.shuffle([...this.toRepeat]);
            this.toRepeat = [];
        }

        if (this.toLearn.length === 0) {
            //alert("END OF GROUP");

            if (this.parent != null)
                this.parent.setNextLevel();
            //вызвать метод простановки новой даты повторения
            //вернуться к окну словаря
            return;
        }

        this.setState({
            card: this.toLearn[0],
            buttons: 'one',
            shorErrorInfo: false,
            showCorrectWordInfo: false
        });

        let headerStr = (this.cards.length - (this.toRepeat.length + this.toLearn.length)) + '/' + this.cards.length;
        this.parent.setHaederString(headerStr);
    }

    playWord = (event) => {
        this.audioWord.src = this.state.card.audio;// Указываем путь к звуку "клика"        
        this.audioWord.autoplay = true; // Автоматически запускаем
    }


    playExample = (event) => {
        //var audio = new Audio(); // Создаём новый элемент Audio
        this.audioExample.src = this.state.card.sampleAudio; // Указываем путь к звуку "клика"
        this.audioExample.autoplay = true; // Автоматически запускаем
    }

    btnIncorrectClick = (event) => {
        this.failAttemptCount = 0;
        this.userInputRef.current.value = '';
        this.toRepeat.push(this.toLearn[0]);
        this.toLearn.shift();
        if (this.toLearn.length === 0) {
            this.toLearn = this.shuffle([...this.toRepeat]);
            this.toRepeat = [];
        }
        if (this.toLearn.length === 0) {
            //alert("END OF GROUP");
            return;
        }

        let headerStr = (this.cards.length - (this.toRepeat.length + this.toLearn.length)) + '/' + this.cards.length;
        this.setState({
            card: this.toLearn[0],
            buttons: 'one',
            shorErrorInfo: false,
            showCorrectWordInfo: false
        });

        this.parent.setHaederString(headerStr);
    }

    onKeyUp = (event) => {
        if (event.keyCode === 13) {
            this.userInputRef.current.value = this.userInputRef.current.value.replace('E', 'é')
                .replace('I', 'í').replace('O', 'ó').replace('A', 'á').replace('N', 'ñ').replace('U', 'ú');
            if (this.userInputRef.current.value === this.state.card.word) {
                //alert("Верно!");
                if (this.failAttemptCount > 2)
                    this.btnIncorrectClick(event);
                else
                    this.btnCorrectClick(event);
            }
            else {
                this.failAttemptCount = this.failAttemptCount + 1;
                if (this.failAttemptCount > 2) {
                    //alert(this.state.card.word);
                    this.setState({
                        shorErrorInfo: false,
                        showCorrectWordInfo: true
                    });
                } else {
                    this.setState({
                        shorErrorInfo: true,
                        showCorrectWordInfo: false
                    });
                    // shorErrorInfo:false,
                    // showCorrectWordInfo:false
                }
                //alert("Ошибка");
                //this.btnIncorrectClick(event);
            }
        }
    }

    render() {
        // console.log(this.state.card);
        // console.log(this.state.showExamples ? "SHOW" : "HIDE");
        let exampleDiv = this.parent.state.showExamples && this.state.buttons === 'two' ?
            <div>
                <div className='sampleDiv'>
                    <span className='exampleSpan'>
                        Пример:
                    </span>
                    {this.state.card.sample}
                    <input type="image" className='audioButton' src="images\sound.png" alt="sound" onClick={this.playExample} />
                </div>
                <div className='sampleTranslationDiv'>
                    {this.state.card.sampleTranslation}
                </div>
            </div>
            :
            <div></div>

        let buttonsDiv = this.state.buttons === 'one' ?
            <button type="button" className="btn btn-success checkButton" onClick={this.btnCheckClick}>Подсказать</button> :
            <div>
                {/* <div className='wordDiv'>{this.state.card.word}</div> */}
                {/* <button type="button" className="btn btn-success checkButton" onClick={this.btnCorrectClick}>Верно</button>
                <button type="button" className="btn btn-danger checkButton" onClick={this.btnIncorrectClick} >Неверно</button> */}
            </div>;

        let wordDiv = this.state.buttons === 'one' ?
            <></> :
            <div className='wordDiv'>{this.state.card.word} <input type="image" className='audioButton' src="images\sound.png" alt="sound" onClick={this.playWord} /></div>

        let errorInfo = this.state.shorErrorInfo === true ? <div className="errorInfo">Неверно</div> : <></>
        let correctWordInfo = this.state.showCorrectWordInfo === true ? <div className="correctWordInfo">Введите "{this.state.card.word}"</div> : <></>

        return (
            <div>
                <div className='img'><img src={this.state.card.image} alt="IMG" /></div>
                <div className='translation'>{this.state.card.translation}</div>
                {errorInfo}
                {correctWordInfo}
                <div className='translation'><InputMask className='userInputWord' mask={this.getMaskForWord(this.state.card.word)} ref={this.userInputRef} onKeyUp={(e) => this.onKeyUp(e)} /></div>
                <div className='buttonsDiv'>
                    {wordDiv}
                    {exampleDiv}
                    {buttonsDiv}
                </div>
            </div>
        )
    }
}