
import React, { Component, Fragment } from 'react';
import { NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            cources: []//
        };
    }

    async readUserCources() {
        const token = await authService.getAccessToken();
        const response = await fetch('/api/UserCources', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const cources = await response.json();

        return cources;
    }



    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])
        // this.setState({
        //     isAuthenticated,
        //     userName: user && user.name
        // });
        if (isAuthenticated) {
            const [cources] = await Promise.all([this.readUserCources()]);

            this.setState({
                isAuthenticated,
                userName: user && user.name,
                cources: cources
            });
        }
        else {
            this.setState({
                isAuthenticated,
                userName: user && user.name,
                cources: []
            });
        }
    }

    render() {
        const { isAuthenticated, userName } = this.state;
        if (!isAuthenticated) {
            const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(registerPath, loginPath);
        } else {
            const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(userName, profilePath, logoutPath);
        }
    }

    async onChangeCource(event) {
        console.log(event.currentTarget.value);

        let courceId = event.currentTarget.value;

        const token = await authService.getAccessToken();
        const url = `${window.location.protocol}//${window.location.host}/api/UserCources/${courceId}`;
        await fetch(url, {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        window.location.reload();
    }

    courceSelectComponent() {
        if (this.state.cources.length < 2)
            return (
                <>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/cources">Ejercicios</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/vocabularies">Vocabulario</NavLink>
                    </NavItem>
                </>
            )
        else
            return (
                <>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/cources">Ejercicios</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink tag={Link} className="text-dark" to="/vocabularies">Vocabulario</NavLink>
                    </NavItem>
                    <NavItem>
                        <select className='select-nav text-dark nav-link' onChange={this.onChangeCource}>
                            {this.state.cources.map((userCource, index) =>
                                userCource.isCurrent ?
                                    (<option selected value={userCource.id} >{userCource.cource.courceName}</option>)
                                    :
                                    <option value={userCource.id} >{userCource.cource.courceName}</option>)}
                        </select>
                    </NavItem>
                </>
            )
    }

    authenticatedView(userName, profilePath, logoutPath) {
        return (<Fragment>
            {this.courceSelectComponent()}
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>{userName}</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={logoutPath}>Salir</NavLink>
            </NavItem>
        </Fragment>);
    }

    anonymousView(registerPath, loginPath) {
        return (<Fragment>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Registro</NavLink>
            </NavItem>
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Entrar</NavLink>
            </NavItem>
        </Fragment>);
    }
}
