import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { FetchData } from './components/FetchData';
import { Counter } from './components/Counter';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';
import { Lesson } from './components/Lesson';
import { VocabularyGroup } from './components/VocabularyGroup';
import { Vocabulary } from './components/Vocabulary';
import { TestComponent1 } from './components/TestComponent1';

import './custom.css'
//import { CourcesList } from './components/CourcesList';
import { CourceInfo } from './components/CourceInfo';
//import { ImageByVoice } from './components/ImageByVoice';

export default class App extends Component {
  static displayName = App.name;

  render() {
    console.log(window.location);
    return (
      <Layout>

        <Route exact path='/' component={Home} />
        <Route path='/counter' component={Counter} />
        <AuthorizeRoute path='/cources' component={CourceInfo} />
        <AuthorizeRoute path='/lesson' component={Lesson} />
        <AuthorizeRoute path='/vocabularies' component={Vocabulary} />
        <AuthorizeRoute path='/vocabularyGroup' component={VocabularyGroup} />
        {/* <Route path='/vocabularies' component={Vocabulary} /> */}
        <AuthorizeRoute path='/fetch-data' component={FetchData} />
        <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
        <Route path='/vvv' Component={TestComponent1} />
      </Layout>
    );
  }
}
