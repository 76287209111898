import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'
import './Lesson.css';
import ImageByVoice from './ImageByVoice';
import SentenceContructor from './SentenceСonstructor';
import StatementByVoice from './StatementByVoice';
import JoinValues from './JoinValues';
import currentCourceService from './CurrentCourceService';
import CbxStatement from './CbxStatement';
import FillInTheGaps from './FillInTheGaps';

export class Lesson extends Component {

    static displayName = "Lesson";

    constructor(props) {
        super(props);
        const queryParams = new URLSearchParams(window.location.search);
        this.lessonId = queryParams.get('id');
        this.courceId = queryParams.get('courceId');
        console.log("LESSON_ID = " + this.lessonId);
        this.isRedoMode = false;
        this.state = {
            lesson: null,
            exercises: [],
            exercise: null,
            loading: true
        };
    }

    componentDidMount() {
        this.populateLesson();
    }

    onUnitClick = (e, nmb) => {
        // if (this.state.unit.unitNumber === nmb) return;
        // this.setState({ unit: this.state.units[nmb] });
        // this.setState({ lessons: this.state.units[nmb].lessons });
        // this.setState({ lessonNmb: 0 });
    }

    onExerciseClick = (e, nmb) => {
        console.log("nmb=" + nmb);
        if (this.state.exercise.exerciseNumber === nmb) return;
        console.log("exNmb=" + this.state.exercise.exerciseNumber);
        this.setState({ exercise: this.state.exercises[nmb] });
    }

    setNextExercise = () => {
        // let n = this.state.exercise.exerciseNumber;
        // n = n + 1;
        let n = this.getNextExerciseNumb();

        if (n === this.state.exercises.length) {
            //alert("end of lesson!");
            const url = `${window.location.protocol}//${window.location.host}/cources?id=${this.courceId}`;
            window.location.assign(url);
        }

        else
            this.setState({ exercise: this.state.exercises[n] });
    }

    getNextExerciseNumb() {
        let n = this.state.exercise.exerciseNumber;
        n = n + 1;
        if (this.isRedoMode === true) return n;

        while (n < this.state.exercises.length) {
            if (this.state.lesson.extendExercises[n].isCompleted === false)
                return n;
            n++;
        }
        return n;
    }

    getFirstExerciseNumb(lesson) {
        let n = 0;
        if (this.isRedoMode === true) return n;
        console.log("LESSON=");
        console.log(lesson);
        console.log(lesson.exercises.length);

        while (n < lesson.exercises.length) {
            if (lesson.extendExercises[n].isCompleted === false)
                return n;
            n++;
        }
        return n;
    }



    async setExerciseComplete(exerciseId) {
        const token = await authService.getAccessToken();
        const url = `${window.location.protocol}//${window.location.host}/api/exercise/${exerciseId}`;
        await fetch(url, {
            method: 'PUT',
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
    }

    renderExercise() {
        let exerciseComponent;
        switch (this.state.exercise.reactComponent) {
            case 'ImageByVoice':
                exerciseComponent = < ImageByVoice exerciseId={this.state.exercise.id} parentComponent={this} />;
                break;
            case 'StatementByVoice':
                exerciseComponent = <StatementByVoice exerciseId={this.state.exercise.id} parentComponent={this} />
                break;
            case 'CbxStatement':
                exerciseComponent = <CbxStatement exerciseId={this.state.exercise.id} parentComponent={this} />
                break;
            case 'FillInTheGaps':
                exerciseComponent = <FillInTheGaps exerciseId={this.state.exercise.id} parentComponent={this} />
                break;
            case 'SentenceСonstructor':
                exerciseComponent = <SentenceContructor exerciseId={this.state.exercise.id} parentComponent={this} />
                break;
            case 'JoinValues':
                exerciseComponent = <JoinValues exerciseId={this.state.exercise.id} parentComponent={this} />
                break;
            default:
                exerciseComponent = <div>ERROR</div>
                break;
        }
        return (
            <div id="all">
                <header id="LessonHeader">
                    Урок {this.state.lesson.lessonName}
                </header>
                <div id="main">
                    {exerciseComponent}
                </div>

                <footer id="f">
                    {/* Ejercicios de la lección */}
                    {this.state.exercises.map((exercise, index) =>
                        <div key={exercise.xerciseName + "_" + index}
                            className={exercise.exerciseNumber === this.state.exercise.exerciseNumber ? 'selectedExercise' : 'unselectedExercise'}
                            onClick={(e) => this.onExerciseClick(e, index)}>
                            <span key={"span_" + exercise.xerciseName} className="lessonNumberDiv">{(exercise.exerciseNumber + 1)}</span>
                        </div>
                    )}
                    {/* номер упражнения {this.state.exerciseNmb}

                    кол-во упраженений {this.state.exercises.length} */}
                </footer>
            </div >
        )
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading lesson...</em></p>
            : this.renderExercise();

        return (
            <div>
                {/*<h1 id="tabelLabel" >Weather forecast</h1>
                <p>This component demonstrates fetching data from the server.</p>*/}
                {contents}
            </div>
        );
    }

    async populateLesson() {
        const token = await authService.getAccessToken();
        const url = `${window.location.protocol}//${window.location.host}/api/lesson/${this.lessonId}`;
        //console.log("#####:" + url);
        const response = await fetch(url, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();

        console.log(data);
        var completedCount = data.extendExercises.filter(a => a.isCompleted === true).length;
        console.log("completed count = " + completedCount);
        console.log("allcount = " + data.exercises.length);
        this.isRedoMode = completedCount === data.exercises.length ? true : false;
        console.log("this.isRedoMode = " + this.isRedoMode);
        console.log("startFunc");
        let n = this.getFirstExerciseNumb(data);
        console.log("endFunc");

        this.setState({
            lesson: data,
            exercises: data.exercises,
            exercise: data.exercises[n]
        });

        this.setState({
            loading: false
        });

        // console.log("JastTestValue=" + currentCourceService._testValue);
        // console.log("testValue=" + currentCourceService.GetTestValeu());
        // console.log("testValue=" + currentCourceService.GetTestValeu());
        // console.log("currentCourceId=" + currentCourceService._currentCourceId);
    }
}
