import authService from './api-authorization/AuthorizeService'

export class CurrentCourceService {


    async getCurrentUserCource() {
        let isAuth = await authService.isAuthenticated();
        if (isAuth === false)
            return null;

        const token = await authService.getAccessToken();
        const response = await fetch('/api/cource', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        return data;
    }

    async getUserCources() {
        console.log(1);
        let isAuth = await authService.isAuthenticated();
        console.log(2);
        if (isAuth === false)
            return null;
        console.log(3);
        const token = await authService.getAccessToken();
        const response = await fetch('/api/UserCources', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        console.log(4);
        console.log(response);
        console.log(5);
        const data = await response.json();
        console.log(6);
        console.log(data);
        return data;
    }

    async getCurrentCourceName() {
        console.log('getCurrentCourceName');
        let isAuth = await authService.isAuthenticated();
        if (isAuth === false)
            return '';

        const token = await authService.getAccessToken();
        const response = await fetch('/api/cource', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        console.log(data);
        try {
            return data.courceName;
        }
        catch {
            return 'Ошибка'
        }
    }
}

const currentCourceService = new CurrentCourceService();

export default currentCourceService;