import React, { Component } from 'react';
//import authService from './api-authorization/AuthorizeService'
import './VocabularyGroup.css';

export class VocabularyGuess extends Component {

    static displayName = "VocabularyGuess";

    constructor(props) {
        super(props);
        this.cards = props.cards;
        this.parent = props.parentComponent;
        //this.showExamples = props.showExamples;
        this.audioWord = new Audio(); // Создаём новый элемент Audio
        this.audioExample = new Audio();
        this.toLearn = this.shuffle([...props.cards]);
        this.toRepeat = [];
        this.state = {
            card: this.toLearn[0],
            buttons: 'one',
            //showExamples: false
        };
        this.n = 0;
    }

    shuffle(arr) {
        var j, temp;
        for (var i = arr.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            temp = arr[j];
            arr[j] = arr[i];
            arr[i] = temp;
        }
        return arr;
    }



    btnCheckClick = (event) => {
        this.setState({ buttons: 'two' });
    }

    btnCorrectClick = (event) => {
        this.toLearn.shift();
        if (this.toLearn.length === 0) {
            this.toLearn = this.shuffle([...this.toRepeat]);
            this.toRepeat = [];
        }

        if (this.toLearn.length === 0) {
            //alert("END OF GROUP");

            if (this.parent != null)
                this.parent.setNextLevel();
            //вызвать метод простановки новой даты повторения
            //вернуться к окну словаря
            return;
        }

        this.setState({ card: this.toLearn[0], buttons: 'one' });

        let headerStr = (this.cards.length - (this.toRepeat.length + this.toLearn.length)) + '/' + this.cards.length;
        this.parent.setHaederString(headerStr);
    }

    playWord = (event) => {
        this.audioWord.src = this.state.card.audio;// Указываем путь к звуку "клика"        
        this.audioWord.autoplay = true; // Автоматически запускаем
    }


    playExample = (event) => {
        //var audio = new Audio(); // Создаём новый элемент Audio
        this.audioExample.src = this.state.card.sampleAudio; // Указываем путь к звуку "клика"
        this.audioExample.autoplay = true; // Автоматически запускаем
    }

    btnIncorrectClick = (event) => {
        this.toRepeat.push(this.toLearn[0]);
        this.toLearn.shift();
        if (this.toLearn.length === 0) {
            this.toLearn = this.shuffle([...this.toRepeat]);
            this.toRepeat = [];
        }
        if (this.toLearn.length === 0) {
            //alert("END OF GROUP");
            return;
        }

        let headerStr = (this.cards.length - (this.toRepeat.length + this.toLearn.length)) + '/' + this.cards.length;
        this.setState({ card: this.toLearn[0], buttons: 'one' });
        this.parent.setHaederString(headerStr);
    }


    render() {
        console.log(this.state.card);
        console.log(this.state.showExamples ? "SHOW" : "HIDE");
        let exampleDiv = this.parent.state.showExamples && this.state.buttons === 'two' ?
            <div>
                <div className='sampleDiv'>
                    <span className='exampleSpan'>
                        Пример:
                    </span>
                    {this.state.card.sample}
                    <input type="image" className='audioButton' src="images\sound.png" alt="sound" onClick={this.playExample} />
                </div>
                <div className='sampleTranslationDiv'>
                    {this.state.card.sampleTranslation}
                </div>
            </div>
            :
            <div></div>

        let buttonsDiv = this.state.buttons === 'one' ?
            <button type="button" className="btn btn-success checkButton" onClick={this.btnCheckClick}>Проверить</button> :
            <div>
                {/* <div className='wordDiv'>{this.state.card.word}</div> */}
                <button type="button" className="btn btn-success checkButton" onClick={this.btnCorrectClick}>Верно</button>
                <button type="button" className="btn btn-danger checkButton" onClick={this.btnIncorrectClick} >Неверно</button>
            </div>;

        let wordDiv = this.state.buttons === 'one' ?
            <></> :
            <div className='wordDiv'>{this.state.card.word} <input type="image" className='audioButton' src="images\sound.png" alt="sound" onClick={this.playWord} /></div>

        return (
            <div>
                <div className='img'><img src={this.state.card.image} alt="IMG" /></div>
                <div className='translation'>{this.state.card.translation}</div>
                <div className='buttonsDiv'>
                    {wordDiv}
                    {exampleDiv}
                    {buttonsDiv}
                </div>

            </div>
        )
    }

}