import React, { Component } from 'react';
import currentCourceService from './CurrentCourceService';

export class Home extends Component {
  static displayName = Home.name;

  // constructor(props) {
  //   super();
  // }

  render() {
    return (
      <div>
        <h1>¡Hola a todos!</h1>
        <p>Bienvenido al sitio de aprendizaje de español</p>
      </div>
    );
  }

  // componentDidMount() {
  //   this.populateData();
  // }

  // async populateData() {
  //   console.log("#!#!#! START");
  //   let cources = await currentCourceService.getUserCources();
  //   console.log("#!#!#! FINISH");
  //   console.log(cources);
  // }

}
