import React from 'react';
import authService from './api-authorization/AuthorizeService';
import './JoinValues.css';

export default class JoinValues extends React.Component {

    constructor(props) {
        super();

        this.exercises = [];
        this.exerciseId = props.exerciseId;
        this.parent = props.parentComponent;
        this.firstColVals = [];
        this.secondColVals = [];
        this.secondColShuffledVals = [];
        this.userSelection = [];
        this.correctSelection = [];
        this.firstColumnRowIsSelected = [];

        this.colourAr = ['red', 'green',]
        this.state = {
            loading: true,
            excerciseNmb: 0,
            exercise: null,
            taskText: '',
            col1RowSelectedNmb: -1,
            col2RowSelectedNmb: -1,
            isHelpViewMode: false
        };
    }

    GetCorrectSelection() {
        let result = [];
        for (let i = 0; i < this.secondColVals.length; i++) {
            let val = this.secondColShuffledVals[i];
            let n = this.secondColVals.findIndex(item => item === val);
            result.push(n);
        }
        console.log(this.firstColVals);
        console.log(this.secondColVals);
        console.log(this.secondColShuffledVals);
        console.log(result);


        return result;
    }

    shuffle(arr) {
        var j, temp;
        for (var i = arr.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            temp = arr[j];
            arr[j] = arr[i];
            arr[i] = temp;
        }
        return arr;
    }

    componentDidMount() {
        this.populateData();
    }


    render() {
        console.log("this.state.isHelpViewMode = " + this.state.isHelpViewMode);
        let contents = this.state.loading
            ? <p><em>Loading exercise (sentence contructor)...</em></p>
            // : this.renderComponent();
            : (this.state.isHelpViewMode === true ? this.renderComponentHelp() : this.renderComponent());


        return (
            <div>
                {contents}
            </div>
        );
    }

    col1Click = (e, index) => {
        //если эта же ячейка уже выбрана, то сбрасываем выделение        
        if (this.state.col1RowSelectedNmb === index) {
            this.setState({ col1RowSelectedNmb: -1 });
            this.setState({ col2RowSelectedNmb: -1 });
            return;
        }
        if (this.state.col2RowSelectedNmb === -1)
            this.setState({ col1RowSelectedNmb: index });
        else {
            //сбрасываем во втотой колонке возможное совпадение
            for (let i = 0; i < this.userSelection.length; i++) {
                if (this.userSelection[i] === index)
                    this.userSelection[i] = -1;
            }

            this.userSelection[this.state.col2RowSelectedNmb] = index;
            this.firstColumnRowIsSelected[index] = true;
            this.setState({ col1RowSelectedNmb: -1 });
            this.setState({ col2RowSelectedNmb: -1 });
        }
        return;
    }

    col2Click = (e, index) => {
        if (this.state.col2RowSelectedNmb === index) {
            this.setState({ col1RowSelectedNmb: -1 });
            this.setState({ col2RowSelectedNmb: -1 });
            return;
        }

        //если выбираемая ячейка во второй колнке уже выбрана (т.е. this.userSelection[index]!==-1),
        //то нужно в первой колонке связанное значение сделать невыбранным
        if (this.userSelection[index] !== -1) {
            let n = this.userSelection[index];
            this.firstColumnRowIsSelected[n] = false;
        }

        this.userSelection[index] = -1;

        if (this.state.col1RowSelectedNmb === -1) {
            this.setState({ col2RowSelectedNmb: index });
        }
        else {

            //сбрасываем во втотой колонке возможное совпадение
            for (let i = 0; i < this.userSelection.length; i++) {
                if (this.userSelection[i] === this.state.col1RowSelectedNmb)
                    this.userSelection[i] = -1;
            }

            this.firstColumnRowIsSelected[this.state.col1RowSelectedNmb] = true;
            this.userSelection[index] = this.state.col1RowSelectedNmb;
            this.setState({ col1RowSelectedNmb: -1 });
            this.setState({ col2RowSelectedNmb: -1 });
        }
    }

    btnCheckClick = (event) => {
        let errorCount = 0;
        console.log(this.secondColVals);
        console.log(this.secondColShuffledVals);
        console.log(this.userSelection);
        for (let i = 0; i < this.firstColVals.length; i++) {
            let n = this.userSelection[i];
            if (this.secondColVals[n] === this.secondColShuffledVals[i])
                continue;
            else
                errorCount++;
        }
        if (errorCount > 0)
            alert(errorCount + " ошибок");
        else {
            //alert(" всё верно!");

            let n = this.state.excerciseNmb;
            n = n + 1;

            if (n >= this.exercises.length) {
                if (this.parent != null) {
                    this.parent.setExerciseComplete(this.exerciseId);
                    this.parent.setNextExercise();
                }
                return;
            }
            this.firstColVals = this.exercises[n].column1;
            this.secondColVals = this.exercises[n].column2;
            this.secondColShuffledVals = this.shuffle([...this.secondColVals]);

            this.userSelection = [];
            this.firstColumnRowIsSelected = [];

            for (let i = 0; i < this.secondColVals.length; i++) {
                this.userSelection.push(-1);
                this.firstColumnRowIsSelected.push(false);
            }

            this.correctSelection = this.GetCorrectSelection();
            this.setState({ exercise: this.exercises[n] });
            this.setState({ excerciseNmb: n });
        }
        // let array = this.wordsDivRef.current.children;
        // let result = "";
        // result = array[0].innerText;
        // for (let i = 1; i < array.length; i++) {
        //     result = result + " " + array[i].innerText;
        // }

        // if (result === this.state.exercise.espSentence) {
        //     this.nextOnClick();
        // } else {
        //     alert(" - неверный ответ!");
        // }
    };
    renderComponent() {
        return (
            <div className='joinValuesMainDiv'>
                <div className='joinValuesTextDiv'>
                    {this.state.taskText}
                </div>
                <div>
                    <table className='joinTable'>
                        <tbody>
                            {this.firstColVals.map((fValue, index) => (
                                <tr key={`tr1_${this.state.excerciseNmb}_${index}`}>
                                    <td className={index === this.state.col1RowSelectedNmb ? 'selectedTd' : ''} onClick={(e) => this.col1Click(e, index)}>
                                        <div key={`div1_${this.state.excerciseNmb}_${index}`} className={this.firstColumnRowIsSelected[index] === true ? ('colNmbDiv colNmbDiv' + index) : ('colNmbDiv colNmbDiv-1')} >
                                            <span key={`span1_${this.state.excerciseNmb}_${index}`} className='colNmbSpan'>
                                                {index + 1}
                                            </span>
                                        </div>
                                        {fValue}
                                    </td>
                                    <td className={index === this.state.col2RowSelectedNmb ? 'selectedTd' : ''} onClick={(e) => this.col2Click(e, index)}>
                                        <div key={`div2_${this.state.excerciseNmb}_${index}`} className={'colNmbDiv colNmbDiv' + this.userSelection[index]} >
                                            <span key={`span2_${this.state.excerciseNmb}_${index}`} className='colNmbSpan'>
                                                {this.userSelection[index] === -1 ? 'X' : (this.userSelection[index] + 1)}
                                            </span>
                                        </div>
                                        {this.secondColShuffledVals[index]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='buttons'>
                        <button type="button" className="btn btn-success checkButton" onClick={this.btnCheckClick}>Проверить</button>
                    </div>
                    <div className='helpButtonDiv'  >
                        <input key="helpBtnNormalMode" type="image" src="images\question.png" alt="help" onMouseDown={this.showHelp} onMouseUp={this.hideHelp} onMouseLeave={this.hideHelp} />
                    </div>
                </div>
            </div>
        );
    }

    renderComponentHelp() {
        return (
            <div className='joinValuesMainDiv'>
                <div className='joinValuesTextDiv'>
                    {this.state.taskText}
                </div>
                <div>
                    <table className='joinTable'>
                        <tbody>
                            {this.firstColVals.map((fValue, index) => (
                                <tr key={`help_tr1_${this.state.excerciseNmb}_${index}`}>
                                    <td>
                                        <div key={`help_div1_${this.state.excerciseNmb}_${index}`} className={'colNmbDiv colNmbDiv' + index} >
                                            <span key={`help_span1_${this.state.excerciseNmb}_${index}`} className='colNmbSpan'>
                                                {index + 1}
                                            </span>
                                        </div>
                                        {fValue}
                                    </td>
                                    <td>
                                        <div key={`help_div2_${this.state.excerciseNmb}_${index}`} className={'colNmbDiv colNmbDiv' + this.correctSelection[index]} >
                                            <span key={`help_span2_${this.state.excerciseNmb}_${index}`} className='colNmbSpan'>
                                                {this.correctSelection[index] + 1}
                                            </span>
                                        </div>
                                        {this.secondColShuffledVals[index]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className='buttons'>
                        <button type="button" className="btn btn-success checkButton" onClick={this.btnCheckClick}>Проверить</button>
                    </div>
                    <div className='helpButtonDiv'  >
                        <input key="helpBtnHelpMode" type="image" src="images\question.png" alt="help" onMouseDown={this.showHelp} onMouseUp={this.hideHelp} onMouseLeave={this.hideHelp} />
                    </div>
                </div>
            </div>
        );
    }

    showHelp = (event) => {
        console.log("showHelp");
        if (this.state.isHelpViewMode === true) return;
        this.setState({ isHelpViewMode: true });
    }

    hideHelp = (event) => {
        console.log("hideHelp");
        if (this.state.isHelpViewMode === false) return;
        this.setState({ isHelpViewMode: false });
        // let boolVal = this.state.isHelpViewMode;
        // boolVal = !boolVal;
        // this.setState({ isHelpViewMode: boolVal });
        // console.log(boolVal);

    }

    async populateData() {
        const token = await authService.getAccessToken();
        const response = await fetch('api/exercise/' + this.exerciseId, {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.exercises = data.exercises;
        this.firstColVals = data.exercises[0].column1;
        this.secondColVals = data.exercises[0].column2;
        this.secondColShuffledVals = this.shuffle([...this.secondColVals]);
        //this.userSelection 
        for (let i = 0; i < this.secondColVals.length; i++) {
            this.userSelection.push(-1);
            this.firstColumnRowIsSelected.push(false);
        }
        this.correctSelection = this.GetCorrectSelection();

        this.setState({ taskText: data.taskText });
        this.setState({ exercise: this.exercises[0] });
        this.setState({ excerciseNmb: 0 });
        this.setState({ loading: false });
    }

}